//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    score: {
      type: Number | null,
      default: null,
    },
    starClass: {
      type: String,
      default: '',
    },
  },
  setup (props, { emit }) {
    // star 給予邏輯：
    // base = Math.floor(score)
    // score % 1 < 0.3 給與 base 顆星
    // 0.3 <= score % 1 <= 0.7 給與 base 顆星 + 半顆
    // score % 1 > 0.7 給與 base + 1 顆星
    const count = computed(() => {
      let fullStarCount = 0
      let halfStarCount = 0

      const remain = props.score % 1
      const base = Math.floor(props.score)
      if (remain < 0.3) fullStarCount = base
      else if (remain <= 0.7) {
        fullStarCount = base
        halfStarCount = 1
      } else fullStarCount = base + 1

      return {
        fullStarCount,
        halfStarCount,
      }
    })

    const tempScore = ref(5)

    const setScore = (score) => {
      tempScore.value = score
      emit('score', score)
    }

    return {
      count,

      tempScore,
      setScore,
    }
  },
})
