import PricedCar from '@/classes/PricedCar'
import Store from '@/classes/Store'

/**
 * 「車輛」和「店家」的組合。通常用在車輛和店家高度聯繫的場合，例如按照店家距離來排序車輛。
 *  當 `store` 為 `null` 時意味著這是模糊搜尋的結果
 */
export default class CarStore {
  key = Symbol('')

  car: PricedCar
  store: Store | null
  isAdPromoted: boolean

  constructor (
    car: PricedCar,
    store?: Store | null,
    /**
     * 是否為廣告車輛
     */
    isAdPromoted?: boolean
  ) {
    this.car = car
    this.store = store ?? null
    this.isAdPromoted = !!isAdPromoted
  }
}
