type Preset = {
  name: string
  width?: number
  height?: number
  fit: 'cover' | 'contain'
}

/**
 * 圖片預設值
 * 參考: https://gogoout.atlassian.net/wiki/spaces/GOGOOUT/pages/23986395
 */
const presets: Preset[] = [
  {
    name: 'Home Carousel',
    width: 640,
    height: 360,
    fit: 'cover',
  },
  {
    name: 'Home Collection',
    width: 512,
    height: 288,
    fit: 'cover',
  },
  {
    name: 'Home Suggestion',
    width: 640,
    height: 360,
    fit: 'cover',
  },
  {
    name: 'Home Promotion',
    width: 1280,
    fit: 'cover',
  },
  {
    name: 'Collection Banner',
    width: 1280,
    height: 360,
    fit: 'cover',
  },
  {
    name: 'Collection City',
    width: 160,
    height: 90,
    fit: 'cover',
  },
  {
    name: 'Car',
    width: 800,
    height: 600,
    fit: 'cover',
  },
  {
    name: 'Store Banner',
    width: 1280,
    height: 360,
    fit: 'cover',
  },
  {
    name: 'Signup Reference',
    width: 1280,
    height: 640,
    fit: 'cover',
  },
  {
    name: 'About Banner',
    width: 1280,
    height: 360,
    fit: 'cover',
  },
  {
    name: 'Order Result Promotion',
    width: 1280,
    fit: 'cover',
  },
  {
    name: 'Suggestion Store Ad',
    width: 512,
    height: 288,
    fit: 'cover',
  },
]

export default presets
